const TOKEN_KEY = "user-token";

const saveUserToken = (token: string) => localStorage.setItem(TOKEN_KEY, token);

const deleteUserToken = () => localStorage.removeItem(TOKEN_KEY);

const getUserToken = () => localStorage.getItem(TOKEN_KEY) || null;

const StorageService = { saveUserToken, deleteUserToken, getUserToken };

export default StorageService;
