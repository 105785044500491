// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
          user-select: none; }
  .ui-spinner__loader > img {
    width: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
`, "",{"version":3,"sources":["webpack://./src/components/AppSpinner/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,0CAAyC;EACzC,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAiB;UAAjB,iBAAiB,EAAA;EAEjB;IACE,YAAY;IACZ,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC,EAAA","sourcesContent":[".ui-spinner {\r\n  position: fixed;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: rgba(255, 255, 255, .7);\r\n  z-index: 999999999;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  user-select: none;\r\n\r\n  &__loader > img {\r\n    width: 100px;\r\n    position: fixed;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
