// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-input-container {
  display: flex;
  gap: 10px;
  width: 80%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  left: 10%;
  margin-top: 20px;
  background-color: #edecec; }
  .chat-input-container__input {
    width: 90%; }
    .chat-input-container__input .form-control {
      border: 2px solid #595959;
      padding: 10px; }
  .chat-input-container__button {
    height: max-content;
    border-radius: 24px;
    border: 2px solid #439b60;
    font-size: 17px;
    font-weight: 500;
    min-width: 8%; }
`, "",{"version":3,"sources":["webpack://./src/features/Chatbot/Components/ChatInput/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,UAAU;EACV,YAAY;EACZ,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,SAAS;EACT,SAAS;EACT,gBAAgB;EAChB,yBAAyB,EAAA;EAEzB;IACE,UAAU,EAAA;IADX;MAGG,yBAAyB;MACzB,aAAa,EAAA;EAIjB;IACE,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,aAAa,EAAA","sourcesContent":[".chat-input-container {\r\n  display: flex;\r\n  gap: 10px;\r\n  width: 80%;\r\n  margin: auto;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  position: sticky;\r\n  bottom: 0;\r\n  left: 10%;\r\n  margin-top: 20px;\r\n  background-color: #edecec;\r\n\r\n  &__input {\r\n    width: 90%;\r\n    .form-control {\r\n      border: 2px solid #595959;\r\n      padding: 10px;\r\n    }\r\n  }\r\n\r\n  &__button {\r\n    height: max-content;\r\n    border-radius: 24px;\r\n    border: 2px solid #439b60;\r\n    font-size: 17px;\r\n    font-weight: 500;\r\n    min-width: 8%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
