// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-container {
  width: 100%;
  margin-top: 30px;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 10px; }
  .upload-container__history {
    width: 48%; }
  .upload-container__button {
    width: 200px;
    font-size: 18px;
    font-weight: 400;
    margin: 42px auto 20px;
    display: flex !important;
    justify-content: center; }
  .upload-container__upload {
    width: 40%; }
    .upload-container__upload-file .progress {
      background-color: #bebfbf; }
    .upload-container__upload-file-item .item-file {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px; }
`, "",{"version":3,"sources":["webpack://./src/features/UploadFiles/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B,SAAS,EAAA;EAET;IACE,UAAU,EAAA;EAGZ;IACE,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB,wBAAwB;IACxB,uBAAuB,EAAA;EAGzB;IACE,UAAU,EAAA;IACT;MAEG,yBAAyB,EAAA;IAE1B;MAEG,aAAa;MACb,mBAAmB;MACnB,2BAA2B;MAC3B,SAAS,EAAA","sourcesContent":[".upload-container {\r\n  width: 100%;\r\n  margin-top: 30px;\r\n  height: 100%;\r\n  display: flex;\r\n  justify-content: space-evenly;\r\n  gap: 10px;\r\n\r\n  &__history {\r\n    width: 48%;\r\n  }\r\n\r\n  &__button {\r\n    width: 200px;\r\n    font-size: 18px;\r\n    font-weight: 400;\r\n    margin: 42px auto 20px;\r\n    display: flex !important;\r\n    justify-content: center;\r\n  }\r\n\r\n  &__upload {\r\n    width: 40%;\r\n    &-file {\r\n      .progress {\r\n        background-color: #bebfbf;\r\n      }\r\n      &-item {\r\n        .item-file {\r\n          display: flex;\r\n          align-items: center;\r\n          justify-content: flex-start;\r\n          gap: 10px;\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
