import Button from "react-bootstrap/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import "./index.scss";
import { FC, ReactNode, memo } from "react";

interface Props extends ModalProps {
  title?: ReactNode;
  isVisible: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const WarningModal: FC<Props> = memo(({ ...props }) => {
  const { isVisible, onClose, onConfirm, title } = props;

  return (
    <Modal
      show={isVisible}
      onHide={onClose}
      centered
      className="ui-modal-error"
    >
      <Modal.Header>
        <Modal.Title>警告</Modal.Title>
      </Modal.Header>
      <Modal.Body>{title}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onConfirm}>
          はい
        </Button>
        <Button variant="success" onClick={onClose}>
          いいえ
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default WarningModal;

WarningModal.defaultProps = {
  content: "",
};
