import React, { FC, useMemo } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { PATH_NAME, SIDEBAR_DATA } from "configs";
import { FiLogOut } from "react-icons/fi";
import StorageService from "features/Auth/Api/Storage";
import SidebarItem from "./SidebarItem";

const AppSidebar: FC = ({ ...props }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    StorageService.deleteUserToken();
    navigate(PATH_NAME.LOGIN);
  };

  const useName = useMemo(() => {
    return StorageService.getUserToken();
  }, []);

  return (
    <div className="ui-sidebar col-2">
      <div className="ui-sidebar__header">
        <div className="ui-sidebar__user">
          <div
            className="ui-sidebar__user-name"
            onClick={() => navigate(PATH_NAME.HOME)}
          >
            {useName}
          </div>
          <div className="ui-sidebar__user-logout" onClick={handleLogout}>
            <FiLogOut />
          </div>
        </div>
        <hr />
      </div>

      <div className="ui-sidebar__item">
        {!!(SIDEBAR_DATA && SIDEBAR_DATA.length) &&
          SIDEBAR_DATA.map((item, index) => (
            <SidebarItem key={index} data={item} />
          ))}
      </div>
    </div>
  );
};

export default AppSidebar;
