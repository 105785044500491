import AppHttpClient from "../../../core/http/index";

const uploadFile = (username: string, body: FormData, options: any) =>
  AppHttpClient.postFormData(
    `/upload-files?username=${username}`,
    body,
    options
  );

const getUploadedFile = (username: string) =>
  AppHttpClient.post(`/get_uploaded_file?username=${username}`, {});

const deleteUploadedFile = (username: string, file_path: string) =>
  AppHttpClient.post(
    `/delete_file?username=${username}&file_path=${encodeURIComponent(
      file_path
    )}`,
    {}
  );

const checkUploadedFileName = (username: string, fileName: string[]) =>
  AppHttpClient.post(`/file_checker?username=${username}`, fileName);

const UploadService = {
  uploadFile,
  getUploadedFile,
  deleteUploadedFile,
  checkUploadedFileName,
};
export default UploadService;
