// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-message-container__guideline {
  text-align: center;
  margin-top: 5%; }
  .chat-message-container__guideline h1 {
    font-size: 32px; }
  .chat-message-container__guideline__content {
    display: flex;
    max-width: 60%;
    margin: auto;
    justify-content: space-between;
    margin-top: 30px;
    gap: 20px; }
    .chat-message-container__guideline__content__item {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 30%; }
      .chat-message-container__guideline__content__item .content__item-icon {
        font-size: 24px; }
      .chat-message-container__guideline__content__item .content__item-header {
        font-size: 22px;
        font-weight: 500; }
      .chat-message-container__guideline__content__item .content__item-box {
        font-size: 16px;
        font-weight: 400;
        min-height: 30px;
        border: 1px solid #dddddd;
        background-color: #dddddd;
        border-radius: 8px;
        padding: 16px 26px;
        color: #000; }
`, "",{"version":3,"sources":["webpack://./src/features/Chatbot/Components/ChatExample/index.scss"],"names":[],"mappings":"AACE;EACE,kBAAkB;EAClB,cAAc,EAAA;EAFf;IAIG,eAAe,EAAA;EAGjB;IACE,aAAa;IACb,cAAc;IACd,YAAY;IACZ,8BAA8B;IAC9B,gBAAgB;IAChB,SAAS,EAAA;IACT;MACE,aAAa;MACb,sBAAsB;MACtB,SAAS;MACT,UAAU,EAAA;MAJX;QAQK,eAAe,EAAA;MARpB;QAWK,eAAe;QACf,gBAAgB,EAAA;MAZrB;QAeK,eAAe;QACf,gBAAgB;QAChB,gBAAgB;QAChB,yBAAyB;QACzB,yBAAyB;QACzB,kBAAkB;QAClB,kBAAkB;QAClB,WAAW,EAAA","sourcesContent":[".chat-message-container {\r\n  &__guideline {\r\n    text-align: center;\r\n    margin-top: 5%;\r\n    h1 {\r\n      font-size: 32px;\r\n    }\r\n\r\n    &__content {\r\n      display: flex;\r\n      max-width: 60%;\r\n      margin: auto;\r\n      justify-content: space-between;\r\n      margin-top: 30px;\r\n      gap: 20px;\r\n      &__item {\r\n        display: flex;\r\n        flex-direction: column;\r\n        gap: 20px;\r\n        width: 30%;\r\n\r\n        .content__item {\r\n          &-icon {\r\n            font-size: 24px;\r\n          }\r\n          &-header {\r\n            font-size: 22px;\r\n            font-weight: 500;\r\n          }\r\n          &-box {\r\n            font-size: 16px;\r\n            font-weight: 400;\r\n            min-height: 30px;\r\n            border: 1px solid #dddddd;\r\n            background-color: #dddddd;\r\n            border-radius: 8px;\r\n            padding: 16px 26px;\r\n            color: #000;\r\n          }\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
