import { ReactNode } from "react";
import { FiUpload, FiSettings } from "react-icons/fi";

export const PATH_NAME = {
  DEFAULT: "/",
  LOGIN: "/login",
  HOME: "/admin",
  UPLOAD: "/upload",
  CHATBOT: "/chatbot",
  SETUP: "/setup",
};

interface SidebarData {
  icon: ReactNode;
  label: string;
  path?: string;
  end?: boolean;
}

const SIDEBAR_DATA: SidebarData[] = [
  {
    icon: <FiUpload />,
    label: "ファイルアップロード",
    path: PATH_NAME.UPLOAD,
    end: false,
  }
];

const ACCEPT_FILES = [
  "text/plain",
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export { SIDEBAR_DATA, ACCEPT_FILES };
