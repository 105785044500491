import React, { FC, Suspense } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { AppRoute } from "./routes.model";
import StorageService from "features/Auth/Api/Storage";
import Homepage from "pages/Homepage";
import LoginPage from "pages/Login";
import { PATH_NAME } from "configs";
import UploadPage from "pages/UploadFiles";
import AppLayout from "components/AppLayout";
import SetupPage from "pages/Setup";
import ChatbotPage from "pages/Chatbot";

// App routes
const appRoutes: AppRoute[] = [
  {
    path: PATH_NAME.DEFAULT,
    element: <ChatbotPage />,
    requiredAuth: false,
  },
  {
    path: PATH_NAME.HOME,
    element: <Homepage />,
    requiredAuth: true,
  },
  {
    path: PATH_NAME.LOGIN,
    element: <LoginPage />,
    requiredAuth: false,
  },
  {
    path: PATH_NAME.UPLOAD,
    element: <UploadPage />,
    requiredAuth: true,
  },
  {
    path: PATH_NAME.CHATBOT,
    element: <ChatbotPage />,
    requiredAuth: false,
  }
];

const renderRoutes = () => {
  return (
    appRoutes &&
    appRoutes.map((appRoute, index) => {
      const element = appRoute.requiredAuth ? (
        <RequireAuth>{appRoute.element}</RequireAuth>
      ) : (
        <NotRequireAuth>{appRoute.element}</NotRequireAuth>
      );
      return <Route key={index} path={appRoute.path} element={element} />;
    })
  );
};

const NotRequireAuth = ({ children }: { children: JSX.Element }) => {
  return children;
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const isAuthenticated = !!StorageService.getUserToken();
  if (isAuthenticated) {
    return <AppLayout>{children}</AppLayout>;
  } else {
    return <Navigate to={`${PATH_NAME.LOGIN}`} state={{ from: location }} />;
  }
};

const AppRoutes: FC = () => {
  return (
    <Suspense>
      <BrowserRouter>
        <Routes>{renderRoutes()}</Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRoutes;
