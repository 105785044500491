// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-message-custom .toast-message-danger .toast-header,
.toast-message-custom .toast-message-danger .toast-body {
  background-color: #f9e1e5;
  color: #af233a;
  border-color: #f4c8cf; }

.toast-message-custom .toast-message-success .toast-header,
.toast-message-custom .toast-message-success .toast-body {
  background-color: #d6f0e0;
  color: #0d6831;
  border-color: #c0e7d0; }
`, "",{"version":3,"sources":["webpack://./src/components/AppToastMessage/index.scss"],"names":[],"mappings":"AAAA;;EAIM,yBAAyB;EACzB,cAAc;EACd,qBAAqB,EAAA;;AAN3B;;EAaM,yBAAyB;EACzB,cAAc;EACd,qBAAqB,EAAA","sourcesContent":[".toast-message-custom {\r\n  .toast-message-danger {\r\n    .toast-header,\r\n    .toast-body {\r\n      background-color: #f9e1e5;\r\n      color: #af233a;\r\n      border-color: #f4c8cf;\r\n    }\r\n  }\r\n\r\n  .toast-message-success {\r\n    .toast-header,\r\n    .toast-body {\r\n      background-color: #d6f0e0;\r\n      color: #0d6831;\r\n      border-color: #c0e7d0;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
