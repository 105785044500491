import { LuSun } from "react-icons/lu";
import { RiFlashlightLine } from "react-icons/ri";
import { ImWarning } from "react-icons/im";
import "./index.scss";
import { ReactNode } from "react";

interface ExampleData {
  icon: ReactNode;
  title: string;
  content: string[];
}

const ChatExample = () => {
  const exampleData: ExampleData[] = [
    {
      icon: <LuSun />,
      title: "例",
      content: [
        "2020年の日本の人口は？",
        "JavascriptでHTTPリクエストを行うにはどうすればよいか?",
      ],
    },
    {
      icon: <RiFlashlightLine />,
      title: "能力",
      content: [
        "ユーザーが会話の初めに言ったことを覚えている",
        "不適切な要求を拒否するように訓練されている",
      ],
    },
    {
      icon: <ImWarning />,
      title: "制限事項",
      content: [
        "時折、誤った情報が生成される可能性がある",
        "アップロードされたドキュメントに関する限られた知識",
      ],
    },
  ];
  return (
    <div className="chat-message-container__guideline">
      <h1>AIチャットボットへようこそ</h1>
      <div className="chat-message-container__guideline__content">
        {exampleData.map((data, index) => (
          <div
            className="chat-message-container__guideline__content__item"
            key={index}
          >
            <div className="content__item-icon">{data.icon}</div>
            <div className="content__item-header">{data.title}</div>
            {data.content.map((content, idx) => (
              <div className="content__item-box" key={idx}>
                {content}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatExample;
