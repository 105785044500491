import { FileInfo } from "features/UploadFiles/Types/index.model";
import { create } from "zustand";

interface FileState {
  uploadedFiles: FileInfo[];
  setUploadedFiles: (files: FileInfo[]) => void;
  removeFile: (file: FileInfo) => void;
}

export const useUploadFilesStore = create<FileState>((set, get) => ({
  uploadedFiles: [],
  setUploadedFiles: (files: FileInfo[]) => {
    set({
      uploadedFiles: files,
    });
  },
  removeFile: (fileRemove: FileInfo) =>
    set((state) => ({
      uploadedFiles: state.uploadedFiles.filter(
        (file) => file.ID !== fileRemove.ID
      ),
    })),
}));
