import React, { useRef } from "react";
import "./index.scss";
import { Button, Form } from "react-bootstrap";
import { MessageParams, MessageType } from "features/Chatbot/Types/index.model";
import ChatbotService from "features/Chatbot/APi";
import { useChatbotStore } from "stores/useChatbot";
import { saveChatHistoryCookie } from "features/Chatbot/APi/ChatHistory";
import { useSearchParams } from "react-router-dom";

const ChatInput = () => {
  const {
    setNewChatContent,
    setMessageStream,
    isStreaming,
    setIsStreaming,
    removeLastMess,
  } = useChatbotStore();
  const formRef = useRef(null);
  const [searchParams] = useSearchParams();
  const userName = searchParams.get("username");

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const formValue: any = event.currentTarget;
    const messParams: MessageParams = {
      inputs: formValue.currentMessage?.value,
      username: userName || "",
      parameters: {},
    };

    if (formValue.currentMessage?.value.trim() === "") {
      (formRef.current as any).reset();
      return;
    }

    setNewChatContent(MessageType.USER, formValue.currentMessage?.value);
    saveChatHistoryCookie(MessageType.USER, formValue.currentMessage?.value);
    (formRef.current as any).reset();

    try {
      setIsStreaming(true);
      setMessageStream("");
      setNewChatContent(MessageType.CHATBOT, "");
      const response = await ChatbotService.getMessageChatbot(messParams);
      if (response.body) {
        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        let beforeValue: string = "";
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            setIsStreaming(false);
            break;
          }

          const list = (beforeValue + value)
            .split("data:")
            .slice(1)

          if (list.length === 0) {
            continue;
          }

          if (!isValidJson(list[list.length - 1])) {
            beforeValue = "data:" + list.pop();
          } else {
            beforeValue = "";
          }

          const listConvert = list.map((item) => JSON.parse(item));

          const messConvert = listConvert
            .map((item) => item.token.text)
            .join("");

          if (
            listConvert[0].details &&
            listConvert[0].details.finish_reason === "stop"
          ) {
            saveChatHistoryCookie(
              MessageType.CHATBOT,
              listConvert[0].generated_text
            );
          } else {
            setMessageStream(messConvert);
          }
        }
      }
    } catch (e) {
      setIsStreaming(false);
      removeLastMess();
      console.log(e);
    }
  };

  const isValidJson = (value: string) => {
    try {
      JSON.parse(value);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <Form
      className="chat-input-container"
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <Form.Group
        className="chat-input-container__input"
        controlId="currentMessage"
      >
        <Form.Control
          type="text"
          placeholder="こちらでメッセージを入力してください..."
          className="rounded-5"
        />
      </Form.Group>

      <Button
        className="chat-input-container__button"
        variant="success"
        type="submit"
        disabled={isStreaming}
      >
        送信
      </Button>
    </Form>
  );
};

export default ChatInput;
