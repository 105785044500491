import React, { FC, ReactNode } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./index.scss";
import AppSidebar from "components/AppSildebar";

interface Props {
  children: ReactNode;
}

const AppLayout: FC<Props> = ({ ...props }) => {
  const { children } = props;

  return (
    <div className="ui-layout">
      <Row align="top">
        <Col xs={2} className="px-0">
          <AppSidebar />
        </Col>

        <Col xs={10} className="px-0">
          <div className="ui-layout__children">{children}</div>
        </Col>
      </Row>
    </div>
  );
};

export default AppLayout;
