import { MessageParams } from "../Types/index.model";

const baseUrl = process.env.REACT_APP_API_URL;

const getMessageChatbot = (body: MessageParams) =>
  fetch(`${baseUrl}generate_stream`, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

const ChatbotService = {
  getMessageChatbot,
};
export default ChatbotService;
