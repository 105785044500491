// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  max-width: 50%;
  width: 400px;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .login-container__title {
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: bold;
    text-align: center; }
  .login-container__button {
    width: 200px;
    font-size: 18px;
    font-weight: 400;
    margin: 20px auto; }
`, "",{"version":3,"sources":["webpack://./src/features/Auth/index.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB,EAAA;EACvB;IACE,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,kBAAkB,EAAA;EAEpB;IACE,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,iBAAiB,EAAA","sourcesContent":[".login-container {\r\n  max-width: 50%;\r\n  width: 400px;\r\n  margin: auto;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  &__title {\r\n    margin-bottom: 20px;\r\n    font-size: 32px;\r\n    font-weight: bold;\r\n    text-align: center;\r\n  }\r\n  &__button {\r\n    width: 200px;\r\n    font-size: 18px;\r\n    font-weight: 400;\r\n    margin: 20px auto;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
