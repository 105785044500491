import StorageService from "../Storage";
import AppHttpClient from "../../../../core/http/index";
import { UserParams } from "features/Auth/Types/index.model";

function logout() {
  StorageService.deleteUserToken();
}

const isAuthenticated = () => !!StorageService.getUserToken();

const login = (data: UserParams) => AppHttpClient.post("/login", data);

const getUserInfo = (useName: string) =>
  AppHttpClient.post(`/get_user_info?username=${useName}`, {});

const UserService = {
  login,
  isAuthenticated,
  logout,
  getUserInfo,
};
export default UserService;
