// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-modal-error .modal-header {
  padding: 6px 16px; }

.ui-modal-error .modal-body {
  min-height: 90px;
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  justify-content: center; }

.ui-modal-error .modal-footer {
  border-top: unset;
  justify-content: center;
  padding-top: 0; }
  .ui-modal-error .modal-footer button {
    padding: 6px 32px; }
`, "",{"version":3,"sources":["webpack://./src/components/AppErrorModal/index.scss"],"names":[],"mappings":"AAAA;EAEI,iBAAiB,EAAA;;AAFrB;EAKI,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,uBAAuB,EAAA;;AAV3B;EAaI,iBAAiB;EACjB,uBAAuB;EACvB,cAAc,EAAA;EAflB;IAiBM,iBAAiB,EAAA","sourcesContent":[".ui-modal-error {\r\n  .modal-header {\r\n    padding: 6px 16px;\r\n  }\r\n  .modal-body {\r\n    min-height: 90px;\r\n    align-items: center;\r\n    display: flex;\r\n    font-size: 18px;\r\n    font-weight: 400;\r\n    justify-content: center;\r\n  }\r\n  .modal-footer {\r\n    border-top: unset;\r\n    justify-content: center;\r\n    padding-top: 0;\r\n    button {\r\n      padding: 6px 32px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
