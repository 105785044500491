import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import UserService from "../../features/Auth/Api/Login";
import { setErrorState } from "stores/useGlobalState";

let navigate: any;

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data.status === "error") {
      setErrorState(response.data.content);
    }
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      UserService.logout();
      navigate("/login");
    }

    return Promise.reject(error);
  }
);

export default instance;
