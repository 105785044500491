import { FC } from "react";
import "./index.scss";
import { NavLink } from "react-router-dom";
import { SidebarData } from "routes/routes.model";

interface Props {
  data: SidebarData;
}

const SidebarItem: FC<Props> = ({ ...props }) => {
  const { data } = props;

  return (
    <NavLink to={data.path as string} end={data.end}>
      {({ isActive }) => (
        <div
          className="ui-sidebar-item"
          style={{
            backgroundColor: isActive ? "#edecec" : "#FFF",
          }}
        >
          <div className="ui-sidebar-item__icon" style={{ textAlign: "left" }}>
            {data.icon}
          </div>
          <div
            className="ui-sidebar-item__label"
            style={{
              fontWeight: isActive ? "600" : "400",
            }}
          >
            {data.label}
          </div>
        </div>
      )}
    </NavLink>
  );
};

export default SidebarItem;
