import { FC, useMemo, useState } from "react";
import { FileInfo } from "features/UploadFiles/Types/index.model";
import { FaRegTrashAlt } from "react-icons/fa";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useUploadFilesStore } from "stores/useUploadFiles";
import WarningModal from "components/AppWaringModal";
import UploadService from "features/UploadFiles/Api";
import StorageService from "features/Auth/Api/Storage";

const UploadedHistory: FC = () => {
  const { removeFile, uploadedFiles } = useUploadFilesStore();
  const [showModalRemove, setShowModalRemove] = useState<boolean>();
  const [fileSelected, setFileSelected] = useState<FileInfo>();
  const userName = useMemo(() => StorageService.getUserToken() || "", []);

  const convertFileName = (fileName: string) =>
    fileName.split(`storage/${userName}/`)[1] || fileName;

  const processing = (processed: number) => (
    <div className="snippet" data-title="dot-flashing">
      <div className="stage">
        {Number(processed).toFixed(2)} %<div className="dot-flashing"></div>
      </div>
    </div>
  );

  const convertProcessed = (processed: number | null) =>
    processed
      ? processed === 100
        ? "完了済み"
        : processing(processed)
      : processing(0);

  const columns = [
    {
      dataField: "fileName",
      text: "ファイル名",
      sort: true,
    },
    {
      dataField: "processed",
      text: "処理済み",
    },
    {
      dataField: "action",
      text: "",
    },
  ];

  const filesData = useMemo(
    () =>
      uploadedFiles &&
      uploadedFiles
        .sort(
          (a, b) =>
            Number(new Date(b.LastUpdateTime)) -
            Number(new Date(a.LastUpdateTime))
        )
        .map((file: FileInfo, index: number) => ({
          ID: file.ID,
          processed: convertProcessed(file.Processed),
          fileName: convertFileName(file.FilePath),
          action: (
            <FaRegTrashAlt
              style={{
                fontSize: "20px",
                color: "red",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => handleConfirmRemove(file)}
            />
          ),
        })),
    [uploadedFiles]
  );

  const handleConfirmRemove = (file: FileInfo) => {
    setFileSelected(file);
    setShowModalRemove(true);
  };

  const handleRemoveFile = async () => {
    if (fileSelected) {
      setShowModalRemove(false);
      try {
        const response = await UploadService.deleteUploadedFile(
          userName,
          fileSelected.FilePath
        );
        if (response.data.status === "success") {
          removeFile(fileSelected);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const titleWarning = () => (
    <div className="flex-col item-start">
      <div>このファイルを削除しますか？</div>
      <div style={{ fontWeight: "500" }}>
        {fileSelected && convertFileName(fileSelected.FilePath)}
      </div>
    </div>
  );

  return (
    <>
      <WarningModal
        isVisible={!!showModalRemove}
        onConfirm={handleRemoveFile}
        onClose={() => setShowModalRemove(false)}
        title={titleWarning()}
      />
      <div className="upload-container__uploaded-history">
        <div className="upload-container__uploaded-history-title">
          ファイルがアップロードされました
        </div>
        {filesData && (
          <BootstrapTable
            bootstrap4
            keyField="ID"
            data={filesData}
            columns={columns}
            pagination={paginationFactory({
              sizePerPage: 10,
              sizePerPageList: [5, 10, 20],
            })}
          />
        )}
      </div>
    </>
  );
};

export default UploadedHistory;
