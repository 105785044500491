import { useEffect, useState } from "react";
import "./index.scss";
import ChatMessage from "./Components/ChatMessage";
import ChatInput from "./Components/ChatInput";
import { useChatbotStore } from "stores/useChatbot";
import { RiDeleteBin5Line } from "react-icons/ri";
import { removeChatHistoryCookie } from "./APi/ChatHistory";
import WarningModal from "components/AppWaringModal";

let isInit = false;

const ChatbotForm = () => {
  const { getChatHistory, chatContent } = useChatbotStore();
  const [showModalRemove, setShowModalRemove] = useState<boolean>();

  useEffect(() => {
    if (!isInit) {
      isInit = true;
      getChatHistory();
    }
  }, [getChatHistory]);

  const removeChatHistory = () => {
    removeChatHistoryCookie();
    setShowModalRemove(false);
    window.location.reload();
  };

  return (
    <div className="chatbot-container">
      <WarningModal
        isVisible={!!showModalRemove}
        onConfirm={removeChatHistory}
        onClose={() => setShowModalRemove(false)}
        title={"この会話を削除しますか？"}
      />
      <div className="my-3 mx-3 chatbot-container__header">
        <h3>AIチャットボット</h3>
        <span
          className="chatbot-container__header-icon"
          onClick={() => chatContent.length && setShowModalRemove(true)}
        >
          <RiDeleteBin5Line />
          クリア
        </span>
      </div>
      <ChatMessage />
      <ChatInput />
    </div>
  );
};

export default ChatbotForm;
