import "./index.scss";
import { FC, memo } from "react";
import { Toast, ToastContainer, ToastProps } from "react-bootstrap";
import classNames from "classnames";

export interface ToastPropsCustom extends ToastProps {
  message: string | null;
  title: string;
  type?: "success" | "danger" | string;
  isShow: boolean;
  onClose?: () => void;
}

const ToastMessage: FC<ToastPropsCustom> = memo(({ ...props }) => {
  const { message, isShow, onClose, type, title } = props;

  return (
    <ToastContainer
      className={classNames("p-3 toast-message-custom")}
      position={"top-end"}
      style={{ zIndex: 1 }}
    >
      <Toast
        onClose={onClose}
        show={isShow}
        delay={5000}
        autohide
        className={`toast-message-${type}`}
      >
        <Toast.Header>
          <strong className="me-auto">{title}</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
});

export default ToastMessage;

ToastMessage.defaultProps = {
  message: "",
  isShow: false,
};
