// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-sidebar {
  height: 100vh;
  background-color: #fff;
  padding: 30px 20px;
  position: fixed; }
  .ui-sidebar__item > a {
    text-decoration: none; }
  .ui-sidebar__user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .ui-sidebar__user-name {
      font-size: 24px;
      font-weight: bold;
      cursor: pointer; }
    .ui-sidebar__user-logout {
      height: auto;
      font-size: 24px;
      display: flex;
      align-items: center;
      cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/components/AppSildebar/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,eAAe,EAAA;EACd;IAEG,qBAAqB,EAAA;EAIzB;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB,EAAA;IACnB;MACE,eAAe;MACf,iBAAiB;MACjB,eAAe,EAAA;IAEjB;MACE,YAAY;MACZ,eAAe;MACf,aAAa;MACb,mBAAmB;MACnB,eAAe,EAAA","sourcesContent":[".ui-sidebar {\r\n  height: 100vh;\r\n  background-color: #fff;\r\n  padding: 30px 20px;\r\n  position: fixed;\r\n  &__item {\r\n    & > a {\r\n      text-decoration: none;\r\n    }\r\n  }\r\n\r\n  &__user {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    margin-bottom: 20px;\r\n    &-name {\r\n      font-size: 24px;\r\n      font-weight: bold;\r\n      cursor: pointer;\r\n    }\r\n    &-logout {\r\n      height: auto;\r\n      font-size: 24px;\r\n      display: flex;\r\n      align-items: center;\r\n      cursor: pointer;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
