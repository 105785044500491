// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatbot-container {
  background-color: #f1f1f1;
  margin: auto;
  height: 100vh;
  overflow: auto; }
  .chatbot-container__header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .chatbot-container__header-icon {
      padding: 0px 10px;
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer; }
      .chatbot-container__header-icon > svg {
        font-size: 22px; }
`, "",{"version":3,"sources":["webpack://./src/features/Chatbot/index.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,cAAc,EAAA;EACd;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B,EAAA;IAC9B;MACE,iBAAiB;MACjB,eAAe;MACf,aAAa;MACb,mBAAmB;MACnB,SAAS;MACT,eAAe,EAAA;MANhB;QAQG,eAAe,EAAA","sourcesContent":[".chatbot-container {\r\n  background-color: #f1f1f1;\r\n  margin: auto;\r\n  height: 100vh;\r\n  overflow: auto;\r\n  &__header {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    &-icon {\r\n      padding: 0px 10px;\r\n      font-size: 20px;\r\n      display: flex;\r\n      align-items: center;\r\n      gap: 10px;\r\n      cursor: pointer;\r\n      & > svg {\r\n        font-size: 22px;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
