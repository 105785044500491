import "./index.scss";
import AppContent from "components/AppContent";

const HomepageScreen = () => {
  return (
    <AppContent title="">
      <div className="homepage-container">
        <h1>管理画面へようこそ</h1>
      </div>
    </AppContent>
  );
};

export default HomepageScreen;
