// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepage-container {
  margin-top: 10%;
  text-align: center; }
`, "",{"version":3,"sources":["webpack://./src/features/Homepage/index.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB,EAAA","sourcesContent":[".homepage-container {\r\n  margin-top: 10%;\r\n  text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
