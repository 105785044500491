import Button from "react-bootstrap/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import "./index.scss";
import { FC, ReactNode, memo } from "react";

interface Props extends ModalProps {
  content?: ReactNode;
}

const ErrorModal: FC<Props> = memo(({ ...props }) => {
  const { isVisible, onClose, title } = props;

  return (
    <Modal
      show={isVisible}
      onHide={onClose}
      {...props}
      className="ui-modal-error"
    >
      <Modal.Header>
        <Modal.Title>エラー</Modal.Title>
      </Modal.Header>
      <Modal.Body>{title}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default ErrorModal;

ErrorModal.defaultProps = {
  content: "",
};
