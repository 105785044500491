import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import "./index.scss";
import AppContent from "components/AppContent";
import UploadedHistory from "./Components/UploadHistory";
import { useUploadFilesStore } from "stores/useUploadFiles";
import UploadService from "./Api";
import StorageService from "features/Auth/Api/Storage";
import ToastMessage, { ToastPropsCustom } from "components/AppToastMessage";
import { ACCEPT_FILES } from "configs";
import { PiDotFill } from "react-icons/pi";
import WarningModal from "components/AppWaringModal";
import { setErrorState } from "stores/useGlobalState";

const UploadFiles = () => {
  const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const { uploadedFiles, setUploadedFiles } = useUploadFilesStore();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isInit = useRef(false);
  const [showToastContent, setShowToastContent] = useState<
    Partial<ToastPropsCustom>
  >({
    message: null,
  });
  const userName = useMemo(() => StorageService.getUserToken(), []);
  const interval = useRef<any>(null);
  const [showModalConfirmReplaceFile, setShowModalConfirmReplaceFile] =
    useState<boolean>();
  const [existFiles, setExistFiles] = useState<string[]>([]);

  const handleGetUploadedFile = async () => {
    if (userName) {
      try {
        stopGetProcessLog();
        const response = await UploadService.getUploadedFile(userName);
        if (response.data.status === "success") {
          const data = response.data.info;
          setUploadedFiles(data);
          if (
            data.length > 0 &&
            !data.every((file: any) => file.Processed === 100)
          ) {
            startGetProcessLog();
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!isInit.current) {
      isInit.current = true;
      handleGetUploadedFile();
    }
    return () => stopGetProcessLog();
  }, []);

  const startGetProcessLog = () =>
    (interval.current = setInterval(() => {
      handleGetUploadedFile();
    }, 2000));

  const stopGetProcessLog = () => {
    clearInterval(interval.current);
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const fileList = Array.from(e.target.files);
    setUploadFiles(fileList);

    // Check format file
    if (
      fileList.filter((file: File) => !ACCEPT_FILES.includes(file.type))
        .length > 0
    ) {
      setShowToastContent({
        message:
          "テキスト、PDF、Word、Excelファイルのみをアップロードしてください",
        type: "danger",
        title: "エラー",
      });
      return;
    }

    // Check file name exist
    const pathFiles = fileList.map(
      (file: File) => `storage/${userName}/${file.name}`
    );

    if (userName) {
      try {
        const response = await UploadService.checkUploadedFileName(
          userName,
          pathFiles
        );
        if (response.data.info.length > 0) {
          const existFile = response.data.info.map(
            (file: any) => file.FilePath.split(`storage/${userName}/`)[1]
          );
          setExistFiles(existFile);
          setShowModalConfirmReplaceFile(true);
        } else {
          handleUploadFile(false, fileList);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleUploadFile = async (isReplace = false, files: File[]) => {
    const formData = new FormData();
    let currentUploadFiles: File[];

    if (existFiles.length > 0 && !isReplace) {
      currentUploadFiles = files.filter(
        (file: File) => !existFiles.includes(file.name)
      );
    } else {
      currentUploadFiles = files;
    }

    currentUploadFiles.forEach((file: File) => {
      formData.append("files", file);
    });

    const options = {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUploadPercentage(percent);
        }
      },
    };

    if (userName) {
      try {
        setUploadingFiles(currentUploadFiles);
        const response = await UploadService.uploadFile(
          userName,
          formData,
          options
        );
        if (response.data.Result === "OK") {
          setUploadingFiles([]);
          handleGetUploadedFile();
          setUploadPercentage(0);
          setShowToastContent({
            message: "ファイルが正常にアップロードされました!",
            type: "success",
            title: "成功!",
          });
        }
      } catch (error) {
        setUploadingFiles([]);
        setErrorState((error as any).message);
        console.log(error);
      }
    }
  };

  const titleWarning = () => (
    <div className="flex-col item-start">
      <div>このファイルを切り替えますか？</div>
      {existFiles.length &&
        existFiles.map((fileName, idx) => (
          <div key={idx} style={{ fontWeight: "500" }}>
            {fileName}
          </div>
        ))}
    </div>
  );

  const handleConfirmReplaceFiles = () => {
    setShowModalConfirmReplaceFile(false);
    handleUploadFile(true, uploadFiles);
  };

  const handleCloseReplaceFiles = () => {
    setShowModalConfirmReplaceFile(false);
    setUploadFiles([]);
    if (existFiles.length < uploadFiles.length) {
      handleUploadFile(false, uploadFiles);
    }
  };

  const resetBeforeFiles = () => ((inputRef.current as any).value = null);

  return (
    <AppContent title="ファイルアップロード">
      <ToastMessage
        type={showToastContent.type}
        message={showToastContent.message || ""}
        isShow={!!showToastContent.message}
        onClose={() => setShowToastContent({ message: null })}
        title={showToastContent.title || ""}
      />
      <WarningModal
        isVisible={!!showModalConfirmReplaceFile}
        onConfirm={() => handleConfirmReplaceFiles()}
        onClose={() => handleCloseReplaceFiles()}
        title={titleWarning()}
      />
      <div className="upload-container">
        {uploadedFiles.length > 0 && (
          <div className="upload-container__history">
            <UploadedHistory />
          </div>
        )}
        <div className="upload-container__upload">
          <Button
            className="upload-container__button"
            variant="success"
            onClick={handleUploadClick}
          >
            ファイル選択
          </Button>

          <input
            multiple
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
            onClick={() => resetBeforeFiles()}
            style={{ display: "none" }}
            accept={ACCEPT_FILES.join(", ")}
          />
          <div className="upload-container__upload-file mt-3">
            {(uploadPercentage > 0 || uploadPercentage < 100) &&
              uploadingFiles?.length > 0 && (
                <ProgressBar
                  animated
                  variant="success"
                  now={uploadPercentage}
                  label={`${uploadPercentage}%`}
                  className="mb-1"
                />
              )}
            {uploadingFiles?.length > 0 &&
              uploadingFiles.map((file: File, index: number) => (
                <div
                  className="upload-container__upload-file-item mb-2"
                  key={index}
                >
                  <div className="item-file">
                    <PiDotFill />
                    <span className="item-name">{file.name}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </AppContent>
  );
};

export default UploadFiles;
