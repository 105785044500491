import { create } from "zustand";

interface GlobalState {
  globalState: {
    loading?: boolean;
    error?: string | null;
  };
  setErrorState: (error: string | null) => void;
  setLoadingState: (isLoading: boolean) => void;
}

export const useGlobalStateStore = create<GlobalState>((set, get) => ({
  globalState: { loading: false, error: null },
  setErrorState: (error: string | null) => {
    set({ globalState: { error: error } });
  },
  setLoadingState: (isLoading: boolean) => {
    set({ globalState: { loading: isLoading } });
  },
}));

export const setErrorState = (error: string | null) => {
  return useGlobalStateStore.setState({
    globalState: { error: error },
  });
};
