import { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./index.scss";
import UserService from "./Api/Login";
import StorageService from "./Api/Storage";
import { useNavigate } from "react-router-dom";
import { PATH_NAME } from "configs";
import { UserParams } from "./Types/index.model";

const LoginForm = () => {
  const [validated, setValidated] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const formValue = event.currentTarget;
    if (formValue.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    const formData: UserParams = {
      user_name: formValue.userName?.value,
      password: formValue.password?.value,
    };
    if (formData.password && formData.user_name) {
      try {
        const response = await UserService.login(formData);
        if (response && response.data.info.UserName) {
          const userInfo = response.data.info;
          StorageService.saveUserToken(userInfo.UserName);
          navigate(PATH_NAME.HOME);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Form
      className="login-container"
      onSubmit={handleSubmit}
      noValidate
      validated={validated}
    >
      <div className="login-container__title">AIチャット</div>
      <Form.Group className="mb-3 mt-4" controlId="userName">
        <Form.Label>ユーザー名</Form.Label>
        <Form.Control
          type="text"
          placeholder="ユーザー名を入力してください"
          required
        />
        <Form.Control.Feedback type="invalid">
          有効なユーザー名を入力してください
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="password">
        <Form.Label>パスワード</Form.Label>
        <Form.Control
          type="password"
          placeholder="パスワードを入力してください"
          required
        />
        <Form.Control.Feedback type="invalid">
          有効なパスワードを入力してください
        </Form.Control.Feedback>
      </Form.Group>
      <Button
        className="login-container__button"
        variant="success"
        type="submit"
      >
        ログイン
      </Button>
    </Form>
  );
};

export default LoginForm;
