// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-content {
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 30px 40px 30px;
  background-color: #edecec; }
  .ui-content__header {
    height: 88px;
    padding-top: 30px;
    padding-bottom: 20px;
    position: fixed;
    background: #edecec;
    border-bottom: solid 1px #b5b5b5; }
  .ui-content__header-title {
    font-size: 24px;
    font-weight: 500;
    color: #000000; }
  .ui-content__children {
    padding-top: 85px; }
`, "",{"version":3,"sources":["webpack://./src/components/AppContent/index.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB,EAAA;EAEzB;IACE,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;IACpB,eAAe;IACf,mBAAmB;IACnB,gCAAgC,EAAA;EAGlC;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc,EAAA;EAGhB;IACE,iBAAiB,EAAA","sourcesContent":[".ui-content {\r\n  min-height: 100vh;\r\n  overflow-y: auto;\r\n  overflow-x: hidden;\r\n  padding: 0 30px 40px 30px;\r\n  background-color: #edecec;\r\n\r\n  &__header {\r\n    height: 88px;\r\n    padding-top: 30px;\r\n    padding-bottom: 20px;\r\n    position: fixed;\r\n    background: #edecec;\r\n    border-bottom: solid 1px #b5b5b5;\r\n  }\r\n\r\n  &__header-title {\r\n    font-size: 24px;\r\n    font-weight: 500;\r\n    color: #000000;\r\n  }\r\n\r\n  &__children {\r\n    padding-top: 85px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
