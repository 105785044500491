interface MessageParams {
  inputs: string;
  username: string;
  parameters?: {};
}

export enum MessageType {
  USER = 0,
  CHATBOT = 1,
}

interface MessageContent {
  role: MessageType;
  message: string;
}

export type { MessageParams, MessageContent };
