// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-layout .row {
  margin-left: 0;
  margin-right: 0; }
  .ui-layout .row .col-10 {
    padding-right: 0; }

.ui-layout__children {
  height: 100vh;
  overflow: auto; }
`, "",{"version":3,"sources":["webpack://./src/components/AppLayout/index.scss"],"names":[],"mappings":"AAAA;EAEI,cAAc;EACd,eAAe,EAAA;EAHnB;IAKM,gBAAgB,EAAA;;AAGpB;EACE,aAAa;EACb,cAAc,EAAA","sourcesContent":[".ui-layout {\r\n  .row {\r\n    margin-left: 0;\r\n    margin-right: 0;\r\n    .col-10 {\r\n      padding-right: 0;\r\n    }\r\n  }\r\n  &__children {\r\n    height: 100vh;\r\n    overflow: auto;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
