import {
  MessageContent,
  MessageType,
} from "features/Chatbot/Types/index.model";

function saveChatHistoryCookie(role: MessageType, newMessage: string) {
  const chatHistory = getChatHistoryCookie();
  const newChatHistory = [
    ...chatHistory,
    {
      role: role,
      message: newMessage,
    },
  ];
  const expiresDays = 365;
  const date = new Date();
  date.setDate(date.getDate() + expiresDays);
  let expiresTime = "";
  expiresTime = "; expires=" + date.toUTCString();

  const cookieName = "chat_history";
  const cookieValue = JSON.stringify(newChatHistory);
  document.cookie = `${cookieName}=${cookieValue}${expiresTime};path=/chatbot`;
}

function getChatHistoryCookie() {
  const cookieName = "chat_history";
  const cookieValue = document.cookie.match(
    new RegExp(`${cookieName}=([^;]+)`, "i")
  );
  if (cookieValue) {
    return JSON.parse(cookieValue[1]) as MessageContent[] | [];
  }

  return [];
}

function removeChatHistoryCookie() {
  const cookieName = "chat_history";
  document.cookie = cookieName + "=; Path=/chatbot";
}

export { saveChatHistoryCookie, getChatHistoryCookie, removeChatHistoryCookie };
