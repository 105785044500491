import React, { FC, memo } from "react";
import "./index.scss";
import Spinner from "react-bootstrap/Spinner";

interface Props {
  loading: boolean;
}

const AppSpinner: FC<Props> = memo(({ ...props }) => {
  const { loading } = props;

  return loading ? (
    <div className="ui-spinner">
      <div className="ui-spinner__loader">
        <Spinner animation="border" variant="success" />
      </div>
    </div>
  ) : null;
});

export default AppSpinner;
