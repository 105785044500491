import ErrorModal from "components/AppErrorModal";
import "./App.css";
import "./App.scss";
import AppRoutes from "routes/router";
import { useGlobalStateStore } from "stores/useGlobalState";
import { useMemo } from "react";
import AppSpinner from "components/AppSpinner";

function App() {
  const { globalState, setErrorState } = useGlobalStateStore();
  const hasError = useMemo(() => !!globalState.error, [globalState.error]);
  return (
    <>
      <AppSpinner loading={!!globalState.loading} />
      <ErrorModal
        title={globalState.error}
        isVisible={hasError}
        centered
        onClose={() => setErrorState(null)}
      />
      <AppRoutes />;
    </>
  );
}

export default App;
