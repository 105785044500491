import { getChatHistoryCookie } from "features/Chatbot/APi/ChatHistory";
import {
  MessageContent,
  MessageType,
} from "features/Chatbot/Types/index.model";
import { create } from "zustand";

interface MessageState {
  chatContent: MessageContent[] | [];
  messageStream: string;
  isChatLoading: boolean;
  isStreaming: boolean;
  setNewChatContent: (role: MessageType, message: string) => void;
  setMessageStream: (message: string) => void;
  setLoadingState: (isLoading: boolean) => void;
  setIsStreaming: (isStreaming: boolean) => void;
  getChatHistory: () => void;
  removeLastMess: () => void;
}

export const useChatbotStore = create<MessageState>((set, get) => ({
  chatContent: [],
  messageStream: "",
  isChatLoading: false,
  isStreaming: false,
  getChatHistory: () => {
    const chatHistory = getChatHistoryCookie();
    set((state) => ({
      chatContent: [...chatHistory, ...state.chatContent],
    }));
  },
  setNewChatContent: (role: MessageType, newMessage: string) => {
    const chatContent = get().chatContent;
    set({
      chatContent: [
        ...chatContent,
        {
          role: role,
          message: newMessage,
        },
      ],
    });
  },
  setMessageStream: (message: string) => {
    set({
      messageStream: message,
    });
  },
  setIsStreaming: (isLoading: boolean) => {
    set({ isStreaming: isLoading });
  },
  setLoadingState: (isLoading: boolean) => {
    set({ isChatLoading: isLoading });
  },
  removeLastMess: () => {
    const chatContent = get().chatContent;
    chatContent.pop();
    set({
      chatContent: chatContent,
    });
  },
}));
