import { FC, memo } from "react";
import "./index.scss";

interface Props {
  title?: string;
  children?: any;
}

const AppContent: FC<Props> = memo(({ ...props }) => {
  const { title, children } = props;

  return (
    <div className="ui-content">
      <div className="ui-content__header col-10">
        <div className="ui-content__header-title">{title}</div>
      </div>
      <div className="ui-content__children">{children}</div>
    </div>
  );
});

export default AppContent;
