// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-sidebar-item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  padding: 10px; }
  .ui-sidebar-item__icon {
    flex-basis: 20%;
    display: flex;
    justify-content: flex-start;
    font-size: 22px;
    color: #000000; }
  .ui-sidebar-item__label {
    flex-basis: 70%;
    color: #000000;
    font-size: 17px; }
`, "",{"version":3,"sources":["webpack://./src/components/AppSildebar/SidebarItem/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,yBAAiB;UAAjB,iBAAiB;EACjB,eAAe;EACf,aAAa,EAAA;EAEb;IACE,eAAe;IACf,aAAa;IACb,2BAA2B;IAC3B,eAAe;IACf,cAAc,EAAA;EAGhB;IACE,eAAe;IACf,cAAc;IACd,eAAe,EAAA","sourcesContent":[".ui-sidebar-item {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-bottom: 24px;\r\n  user-select: none;\r\n  cursor: pointer;\r\n  padding: 10px;\r\n\r\n  &__icon {\r\n    flex-basis: 20%;\r\n    display: flex;\r\n    justify-content: flex-start;\r\n    font-size: 22px;\r\n    color: #000000;\r\n  }\r\n\r\n  &__label {\r\n    flex-basis: 70%;\r\n    color: #000000;\r\n    font-size: 17px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
