import React, { useEffect, useMemo } from "react";
import "./index.scss";
import { useChatbotStore } from "stores/useChatbot";
import { BiUser } from "react-icons/bi";
import { SiChatbot } from "react-icons/si";
import {
  MessageContent,
  MessageType,
} from "features/Chatbot/Types/index.model";
import ChatExample from "../ChatExample";

const ChatMessage = () => {
  const { chatContent, messageStream, isStreaming } = useChatbotStore();
  const chatContentData = useMemo(() => chatContent, [chatContent]);

  useEffect(() => {
    if (messageStream) {
      const textStream = document.createTextNode(messageStream);
      const endBoxChatbotMess: HTMLElement | null = document.getElementById(
        `chatbot-mess-${chatContent.length - 1}`
      );
      const chatContainer: HTMLElement | null =
        document.getElementById("chat-container");
      if (endBoxChatbotMess) {
        endBoxChatbotMess.appendChild(textStream);
        const chatbotTextTop = endBoxChatbotMess.offsetTop;
        if (chatContainer) {
          chatContainer.scrollTo({
            top: chatbotTextTop,
            behavior: "smooth",
          });
        }
      }
    }
  }, [chatContent.length, messageStream]);

  useEffect(() => {
    const endBoxUserMess: HTMLElement | null = document.getElementById(
      `user-mess-box-${chatContent.length - 1}`
    );
    const endBoxChatbotMess: HTMLElement | null = document.getElementById(
      `chatbot-mess-box-${chatContent.length - 1}`
    );
    const chatContainer: HTMLElement | null =
      document.getElementById("chat-container");
    if (chatContainer) {
      if (endBoxUserMess) {
        const endBoxUserMessTop = endBoxUserMess.offsetTop;
        chatContainer.scrollTo({ top: endBoxUserMessTop, behavior: "smooth" });
      }
      if (endBoxChatbotMess) {
        const endBoxChatbotMessTop = endBoxChatbotMess.offsetTop;
        chatContainer.scrollTo({
          top: endBoxChatbotMessTop,
          behavior: "smooth",
        });
      }
    }
  }, [chatContent.length]);

  useEffect(() => {
    const blinkingMouse: HTMLElement | null =
      document.querySelector(".blinking-cursor");
    if (isStreaming && blinkingMouse && messageStream === "") {
      blinkingMouse.style.display = "inline-block";
    } else if (blinkingMouse) {
      blinkingMouse.style.display = "none";
    }
  }, [chatContent.length, isStreaming, messageStream]);

  return (
    <div className="chat-message-container" id="chat-container">
      {chatContentData.length ? (
        chatContentData.map((messageContent: MessageContent, idx: number) => (
          <div key={idx}>
            {messageContent.role === MessageType.USER && (
              <div
                className="chat-message-container__box box-user"
                id={`user-mess-box-${idx}`}
              >
                <div className="chat-message-container__box-avatar">
                  <BiUser />
                </div>
                <div className="chat-message-container__box-content">
                  {messageContent.message}
                </div>
              </div>
            )}
            {messageContent.role === MessageType.CHATBOT && (
              <div
                className="chat-message-container__box box-admin"
                id={`chatbot-mess-box-${idx}`}
              >
                <div className="chat-message-container__box-avatar">
                  <SiChatbot />
                </div>
                <div
                  className="chat-message-container__box-content"
                  id={`chatbot-mess-${idx}`}
                >
                  {messageContent.message}

                  {idx === chatContent.length - 1 && (
                    <span className="blinking-cursor">|</span>
                  )}
                </div>
              </div>
            )}
          </div>
        ))
      ) : (
        <ChatExample />
      )}
    </div>
  );
};

export default ChatMessage;
